<p-card styleClass="h-full">
  <div class="flex justify-content-between mb-3">
    <div>
      <span class="block text-500 font-medium mb-3">{{ label() }}</span>
      <div class="text-900 font-medium text-xl">
        @if (metric() !== undefined) {
          {{ metric() }}
        } @else {
          <p-skeleton width="4rem"></p-skeleton>
        }
      </div>
    </div>
    <div
      [ngClass]="
        'flex align-items-center justify-content-center border-round ' +
        'bg-' +
        iconColor() +
        '-100'
      "
      style="width: 2.5rem; height: 2.5rem"
    >
      <i
        [ngClass]="
          icon() + ' text-blue-500 text-xl ' + 'text-' + iconColor() + '-500'
        "
      ></i>
    </div>
  </div>
</p-card>
