import { CommonModule } from '@angular/common';
import { Component, input, Signal } from '@angular/core';
import { CardModule } from 'primeng/card';
import { SkeletonModule } from 'primeng/skeleton';

type Metric = string | number | undefined;

export interface MetricCard {
  icon: string;
  iconColor: string;
  label: string;
  metric: Signal<Metric>;
}

@Component({
  standalone: true,
  imports: [CommonModule, SkeletonModule, CardModule],
  selector: 'rc-metric-card',
  templateUrl: './metric-card.component.html',
  styleUrl: './metric-card.component.scss',
})
export class RcMetricCardComponent {
  public metric = input.required<Metric>();
  public icon = input.required<MetricCard['icon']>();
  public iconColor = input.required<MetricCard['iconColor']>();
  public label = input.required<MetricCard['label']>();
}
